// Sliders
(function(root) {
	if($("#events-slider")) {
		$("#events-slider").owlCarousel({
			items: 4,
			loop: false,
			margin: 30,
			navText: [
				'<div class="events-slider__button"><svg class="events-slider__icon"><use xlink:href="img/icons.svg#icon-arrow-prev"></svg></div>',
				'<div class="events-slider__button"><svg class="events-slider__icon"><use xlink:href="img/icons.svg#icon-arrow-next"></svg></div>'
			],
			responsive:{
				0:{
					items: 1
				},

				500: {
					items: 2
				},

				750:{
					items:3
				},

				1000:{
					items: 4,
					nav: true
				}
			}
		});
	}

	if($("#about-slider")) {
		$("#about-slider").owlCarousel({
			items: 6,
			autoWidth:true,
			loop: true,
			margin: 20,
			nav: true,
			navText: [
				'<svg class="about-slider__button about-slider__button_prev"><use xlink:href="img/icons.svg#icon-arrow-prev"></svg>', 
				'<svg class="about-slider__button about-slider__button_next"><use xlink:href="img/icons.svg#icon-arrow-next"></svg>'
			]
		});
	}

	if($("#sh-slider")) {
		$("#sh-slider").owlCarousel({
			items: 6,
			loop: true,
			autoWidth: true,
			margin: 20,
			nav: true,
			navText: [
				'<svg class="shops-slider__button shops-slider__button_prev"><use xlink:href="img/icons.svg#icon-arrow-prev"></svg>', 
				'<svg class="shops-slider__button shops-slider__button_next"><use xlink:href="img/icons.svg#icon-arrow-next"></svg>'
			]
		});
	}
})(window);


// Custom select
(function(root) {
  $('select').selectric({
  	arrowButtonMarkup: '<b class="selectric-button">&#x25be;</b>'
  });
})(window);


// Parallax
(function(root) {
	$('.b-parallax').paroller();
})(window);


// Модальное окно
(function(root) {
	$("a[data-modal]").on('click', function(e) {
		e.preventDefault();
		var modal = $(this).data('modal');

		$("[data-modal=" + modal + "]").toggleClass('active');
	});

	$(".modal__close").on('click', function(e) {
		$(this).closest('.g-modal').removeClass("active");
	});

	$('.g-modal__inner').on('click', function(e) {
		if($(e.target).closest('.modal, .tag-i').length) {
			return;
		}

		$(this).closest('.g-modal').removeClass("active");
	});

	$('body').keyup(function(e) {
		var symbol = e.key;

		if (e.which == 27) {
			$('.g-modal').removeClass("active");
		}
	});


	// select-modal
	$('#select-modal').selectric({
		onChange: function(el) {
			const modal = $(this).val();
			$("[data-modal=" + modal + "]").toggleClass('active');
			$(this).val("").selectric('refresh');
		}
	});
})(window);


// Мобильное меню
(function(root) {

	$("#btn-menu").on('click', function() {
		let $elem = $(this).find('.btn-icon'),
				icons = ['img/icons.svg#icon-menu', 'img/icons.svg#icon-close'];

		if($elem.attr("xlink:href") === icons[0]) {
			$elem.attr("xlink:href", icons[1]);
		} else {
			$elem.attr("xlink:href", icons[0]);
		}

		$(".navigation").toggleClass('navigation_active');
	});

})(window);